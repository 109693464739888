var sw = window.innerWidth;
var sh = window.innerHeight;
var header = document.querySelector("header");
var sticky = 0;

// Font Resizer ------------------- Start
function fontResizer() {
  var perc = parseInt(sw) / 120;
  document.body.style.fontSize = perc + "px";
}

// Landscape Mode off ---------------- Start
const mql = window.matchMedia("(orientation:landscape)");
mql.addEventListener("change", (event) => {
  if (event.matches) {
    if (innerWidth < 930) {
      // console.log("Now in landscape orientation");
      document.getElementById("portrait-warning").style.display = "flex";
    }
  } else {
    // console.log("Now in portrait orientation");
    document.getElementById("portrait-warning").style.display = "none";
  }
});

// Loader ------------------- Start
function pageLoader() {
  setTimeout(function () {
    document.querySelector("html").classList.add("loaded");
    setTimeout(() => {
      document.querySelector(".loader-first").style.display = "none";
    }, 1000);
  }, 700);
}

// Sticky Header ---------------- Start
(function () {
  if (document.querySelector(".header")) {
    // Sticky Header Class Add and Remove ---------------- Start
    function stickyHeader() {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    }
    // Sticky Header ---------------- End

    // Window On Scroll-----------------Start
    window.addEventListener("scroll", (event) => {
      stickyHeader();
    });
  }
})();

// Window on Load ----------------- Start
window.onload = function () {
  pageLoader();
  if (sw > 1199) {
    fontResizer();
  }
};

// Window On Resize ----------------- Start
(function () {
  window.addEventListener("resize", (event) => {
    sw = window.innerWidth;
    sh = window.innerHeight;
    if (sw > 1025) {
      if (sw < 1400 && sw > 1300 && sh > 900) {
      } else {
        fontResizer();
      }
    }
  });
})();

// SVG Convert Code
window.addEventListener("load", function () {
  let svgs = document.querySelectorAll("img.svg-convert");
  svgs.forEach((el) => {
    // const imgID = el.getAttribute("id");
    const imgClass = el.getAttribute("class");
    const imgURL = el.getAttribute("src");

    fetch(imgURL)
      .then((response) => response.text())
      .then((data) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(data, "text/html");
        let svg = xmlDoc.querySelector("svg");
        // if (typeof imgID !== "undefined") {
        //   svg.setAttribute("id", imgID);
        // }

        if (typeof imgClass !== "undefined") {
          svg.setAttribute("class", imgClass + " svg-converted");
        }

        svg.removeAttribute("xmlns:a");

        el.parentNode.replaceChild(svg, el);
      });
  });
});

// Navigation slide Active on a mobile ---------------- Start
(function () {
  if (document.querySelector("#toggle")) {
    let toggleMenu = document.querySelector("#toggle");
    let htmlEl = document.querySelector("html");

    toggleMenu.addEventListener("click", (event) => {
      // console.log('this', event)
      let _this = event.target;
      _this.classList.toggle("active");
      document.querySelector(".mainNavWrapper").classList.toggle("opened");
      document.querySelector(".header").classList.toggle("mainNavOpen");
      htmlEl.classList.toggle("overflow-hidden");
    });
  }
})();

// Language Dropdown Active-------------------Start

if ("ontouchstart" in document.documentElement) {
  // document.querySelector(".megamenu").classList.add("touchDevice");
  var selectedItem = document.getElementsByClassName("langWrapper");
  var k;
  for (k = 0; k < selectedItem.length; k++) {
    selectedItem[k].addEventListener("click", function () {
      this.classList.toggle("open");
    });
  }
} else {
  var selectedItem = document.getElementsByClassName("langWrapper");
  var k;
  for (k = 0; k < selectedItem.length; k++) {
    selectedItem[k].addEventListener("mouseenter", function () {
      this.classList.add("open");
    });
    selectedItem[k].addEventListener("mouseleave", function () {
      this.classList.remove("open");
    });
  }
}
// Language Dropdown Active-------------------End

// -------------------- above code will be common for all websites --------------------------

if (document.querySelector(".homeBanner__figure")) {
  var homeBanner__figure = new Swiper(".homeBanner__figure .swiper", {
    slidesPerView: 1,
    speed: 800,
    spaceBetween: 0,
    allowTouchMove: false,
    autoplay: {
      delay: 3500,
    },
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
  });
}

if (document.querySelector(".homeNews__slider")) {
  var homeNews__slider = new Swiper(".homeNews__slider .swiper", {
    slidesPerView: 1,
    speed: 800,
    grabCursor: true,
    spaceBetween: 15,
    breakpoints: {
      576: {
        slidesPerView: 2,
        spaceBetween: 0,
      },
      1199: {
        slidesPerView: 3,
      },
    },
  });
}

if (document.querySelector(".homeMagazines__slider")) {
  var homeMagazines__slider = new Swiper(".homeMagazines__slider .swiper", {
    slidesPerView: 1.5,
    speed: 800,
    spaceBetween: 15,
    centeredSlides: true,
    loop: true,
    watchSlidesProgress: true,
    allowTouchMove: false,
    autoplay: {
      delay: 1500,
      disableOnInteraction: false,
    },
    breakpoints: {
      576: {
        slidesPerView: 2.25,
        spaceBetween: 30,
      },
      992: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      1199: {
        slidesPerView: 4.75,
        spaceBetween: 44,
      },
    },
    navigation: {
      nextEl: ".homeMagazines__slider .swiper-button-next",
      prevEl: ".homeMagazines__slider .swiper-button-prev",
    },
  });
}

if (document.querySelector(".homeArticles__slider")) {
  var homeArticles__slider = new Swiper(".homeArticles__slider .swiper", {
    slidesPerView: "auto",
    speed: 800,
    grabCursor: true,
    spaceBetween: 15,
    breakpoints: {
      576: {
        spaceBetween: 0,
      },
      1199: {
        spaceBetween: 0,
      },
    },
  });
}

// Show Input File Title-------Start
$(".inputFileWrapper input").on("change", function () {
  var fileLength = $(this)[0].files.length;
  if (fileLength > 0) {
    var value = $(this).val().replace("C:\\fakepath\\", "");

    var placeholder = $(this).parent(".inputFileWrapper").find(".placeholder");

    placeholder.text(value);
  }
});

// Show Input File Title-------End

// Date Picker-------Start

$(document).ready(function () {
  $(".datepicker").datepicker({
    // format: "dd-mm-yyyy",
    //startDate: "+1d",
  });
});

$(".datepicker").change(function () {
  if ($(this).val() !== "") {
    $(this).siblings("label").hide();
  }
});
// Date Picker-------End

// Generic Tabs------Start
$(document).ready(function () {
  //When page loads...
  $(".genericTabsContentWrapper .genericTabsContent").addClass("tabHidden"); //Hide all content
  $(".genericTabs li:first-child")
    .addClass("tabActive")
    .removeClass("tabHidden"); //Activate first tab
  $(".genericTabsContentWrapper .genericTabsContent:first-child").removeClass(
    "tabHidden"
  ); //Show first tab content

  //On Click Event
  $(".genericTabs li").click(function () {
    $(this).siblings().removeClass("tabActive"); //Remove any "active" class
    $(this).addClass("tabActive"); //Add "active" class to selected tab
    $(this)
      .parent(".genericTabs")
      .siblings(".genericTabsContentWrapper")
      .children(".genericTabsContent")
      .addClass("tabHidden"); //Hide all tab content

    var activeTab = $(this).attr("data-attr"); //Find the href attribute value to identify the active tab + content
    $(activeTab).removeClass("tabHidden"); //Fade in the active ID content
    $(activeTab).siblings().addClass("tabHidden");
    return false;
  });
});
// Generic Tabs------End

// if ("ontouchstart" in document.documentElement) {
//   // document.querySelector(".megamenu").classList.add("touchDevice");
//   var selectedItem = document.getElementsByClassName("langWrapper");
//   var k;
//   for (k = 0; k < selectedItem.length; k++) {
//     selectedItem[k].addEventListener("click", function () {
//       this.classList.toggle("open");
//     });
//   }
// } else {
//   var selectedItem = document.getElementsByClassName("langWrapper");
//   var k;
//   for (k = 0; k < selectedItem.length; k++) {
//     selectedItem[k].addEventListener("mouseenter", function () {
//       this.classList.add("open");
//     });
//     selectedItem[k].addEventListener("mouseleave", function () {
//       this.classList.remove("open");
//     });
//   }
// }
// Language Dropdown Active-------------------End

// Generic Accordian-------------------Start

$(".accordianBtn").click(function (e) {
  e.preventDefault();
  var $this = $(this);
  if ($this.next().hasClass("show")) {
    $this.removeClass("show");
    $this.next().removeClass("show");
    $this.next().slideUp(350);
  } else {
    $this.parent().parent().find("li .accordianBtn").removeClass("show");
    $this.parent().parent().find("li .accordianContent").removeClass("show");
    $this.parent().parent().find("li .accordianContent").slideUp(350);
    $this.toggleClass("show");
    $this.next().toggleClass("show");
    $this.next().slideToggle(350);
  }
});
// Generic Accordian-------------------ENd

//  Popup----------------------Start

// $(".viewBtn").click(function () {
//   let attr = $(this).attr("data-attr");
//   $("#" + attr).fadeToggle(250);
//   $(".magazinePopupBg").fadeToggle(250);
//   $("html").toggleClass("overflow-hidden");
// });

$(".magazinePopup .popupClose").click(function () {
  $(this).parent(".magazinePopup").fadeToggle(250);
  $(".magazinePopupBg").fadeToggle(250);
  $("html").toggleClass("overflow-hidden");
});

$(".magazinePopupBg").click(function () {
  $(".magazinePopup").fadeOut(250);
  $(this).fadeOut(250);
  $("html").removeClass("overflow-hidden");
});

$(".formPopup .popupClose").click(function () {
  $(this).closest(".formPopup").fadeToggle(250);
});

$(".signoutBtn").click(function () {
  $(".signoutPopup").fadeIn(250);
  $(".signoutPopupBg").fadeIn(250);
  $("html").addClass("overflow-hidden");
});

$(".signoutPopup .popupClose").click(function () {
  $(this).closest(".signoutPopup").fadeOut(250);
  $(".signoutPopupBg").fadeOut(250);
  $("html").removeClass("overflow-hidden");
});

$(".signoutPopupBg").click(function () {
  $(".signoutPopup").fadeOut(250);
  $(this).fadeOut(250);
  $("html").removeClass("overflow-hidden");
});

$(".deleteBtn").click(function () {
  $(".deletePopup").fadeIn(250);
  $(".deletePopupBg").fadeIn(250);
  $("html").addClass("overflow-hidden");
});

$(".deletePopup .popupClose").click(function () {
  $(this).closest(".deletePopup").fadeOut(250);
  $(".deletePopupBg").fadeOut(250);
  $("html").removeClass("overflow-hidden");
});

$(".deletePopupBg").click(function () {
  $(".deletePopup").fadeOut(250);
  $(this).fadeOut(250);
  $("html").removeClass("overflow-hidden");
});

$(".bodPopupBtn").click(function () {
  let attr = $(this).attr("data-attr");
  $("#" + attr).fadeToggle(250);
  $(".bodPopupBg").fadeToggle(250);
  $("html").toggleClass("overflow-hidden");
});

$(".bodPopup .popupClose").click(function () {
  $(this).parent(".bodPopup").fadeToggle(250);
  $(".bodPopupBg").fadeToggle(250);
  $("html").toggleClass("overflow-hidden");
});

$(".bodPopupBg").click(function () {
  $(".bodPopup").fadeOut(250);
  $(this).fadeOut(250);
  $("html").removeClass("overflow-hidden");
});
//  Popup----------------------End
